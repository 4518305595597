import request from "@/api/axios";
import { apiList as api } from "./apiList";

export function getCourseDetail(id, courseSource = 2) {
  return request({
    url: api.resources.getCourseDetail + id, // 学程详情
    method: "get",
  });
}

/**
 * 获取学程新旧版本(用于新课程详情)
 */
export function getCourseVersion(id) {
  return request({
    url: api.resources.getCourseVersion + id,
    method: "get"
  });
}

/**
 * 根据resId获取libId(习练)
 */
export function getLibIdByResId(resId) {
  return request({
    url: api.resources.getLibIdByResId + resId,
    method: "get"
  });
}

/**
 * 【课程】根据id查询课程详情(新课程详情)
 * @param {*} id 
 * @returns 
 */
export function getCurriculimDetailsById(id) {
  return request({
    url: api.resources.getCurriculimDetailsById + id + '/',
    method: "get",
  });
}

export function getCurriculumDetail(id, source) {
  return request({
    url: api.resources.getCurriculumDetail + id + '/' + source, // 课程详情
    method: "get",
  });
}
export function getCurriculumAboutList(data) {
  return request({
    url: api.resources.getCurriculumAboutList, // 相关课程
    method: "post",
    data
  });
}

/**
 * 获取相关课程(新)
 */
export function getNewCurriculumAboutList(data) {
  return request({
    url: api.resources.getNewCurriculumAboutList, // 相关课程(新)
    method: "post",
    data
  });
}

/**
 * 引用资源(新课程详情)
 * @param {String} resourceId 资源id
 * @param {String} type 1 个人库 2公共库
 * @returns 
 */
export function getNewUseResource(resourceId, type) {
  return request({
    url: api.resources.getNewUseResource + resourceId + '/' + type, // 引用资源(新课程详情)
    method: "get",
  });
}

/**
 * 引用学程(新课程详情)
 * @param {String} resourceId 学程id
 * @returns 
 */
export function getNewUseCourse(courseId) {
  return request({
    url: api.resources.getNewUseCourse + courseId, // 引用学程(新课程详情)
    method: "get",
  });
}

/**
 * 引用习练(新课程详情)
 * @param {String} resId 习练id
 * @returns 
 */
export function getNewUseExercise(resId) {
  return request({
    url: api.resources.getNewUseExercise + resId, // 引用习练(新课程详情)
    method: "put",
  });
}

/**
 * 【课程】引用课程(新课程详情)
 * @returns 
 */
export function getNewUseCurriculum(data) {
  return request({
    url: api.resources.getNewUseCourse + getNewUseCurriculum, // 引用课程(新课程详情)
    method: "post",
    data
  });
}

export function getExerciseDetail(id) {
  return request({
    url: api.resources.getExerciseDetail + id, // 习练详情
    method: "get",
  });
}
export function getToolDetail(toolBusinessId, outlineBusinessId) {
  return request({
    url: api.resources.getToolDetail + `${toolBusinessId}/${outlineBusinessId}`, // 睿智云工具详情
    method: "get",
  });
}
export function getToolUrl(data) {
  return request({
    url: api.resources.getToolUrl, // 睿智云工具地址
    method: "post",
    data
  });
}
export function getResourceInfo(id) {
  return request({
    url: api.resources.getResourceInfo + id, // 睿智云工具地址
    method: "get"
  });
}
export function getPersonResourceInfo(id) {
  return request({
    url: api.resources.getPersonResourceInfo + id, // 获取个人资源详情
    method: "get"
  });
}
export function collectLesson(id,type) { //课程收藏
  return request({
    url: api.resources.collectLesson + id + '/' + type,
    method:'get',
  });
}
export function useLesson(data) { //课程引用
  return request({
    url: api.resources.useLesson,
    method:'post',
    data
  });
}
export function getVipList(params) { // 会员服务列表
  return request({
    url: api.resources.getVipList,
    method: "get",
    params
  });
}
export function getNotAuth(id) { // 无权限列表
  return request({
    url: api.resources.getNotAuth + id,
    method: "get",
  });
}