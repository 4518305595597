import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "previewContent"
};
var _hoisted_2 = {
  key: 0,
  ref: "iframe",
  allowfullscreen: true,
  class: "iframe"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showPath ? (_openBlock(), _createElementBlock("iframe", _hoisted_2, null, 512)) : _createCommentVNode("", true)]);
}