// 卡片名称、icon以及组件名称类型映射
import {
  COURSE_CARD_TYPE,
  TASK_GROUP_CARD_TYPE,
  TASK_CARD_TYPE,
  SUBTASK_CARD_TYPE,
  IMAGE_TEXT_CARD_TYPE,
  EXERCISE_CARD_TYPE,
  SOURCE_CARD_TYPE,
  SUBMIT_CARD_TYPE,
  INTERACT_CARD_TYPE,
  GAUGE_CARD_TYPE
} from './constant'
import {
  COURSE_CARD_ICON,
  TASK_GROUP_CARD_ICON,
  TASK_CARD_ICON,
  SUBTASK_CARD_ICON,
  IMAGE_TEXT_CARD_ICON,
  EXERCISE_CARD_ICON,
  SOURCE_CARD_ICON,
  SUBMIT_CARD_ICON,
  INTERACT_CARD_ICON,
  GAUGE_CARD_ICON
} from './constant'

export default {
  [COURSE_CARD_TYPE]: { name: '学程', icon: COURSE_CARD_ICON, component: 'courseCard' },
  [TASK_GROUP_CARD_TYPE]: { name: '任务群', icon: TASK_GROUP_CARD_ICON, component: 'taskGroupCard' },
  [TASK_CARD_TYPE]: { name: '任务', icon: TASK_CARD_ICON, component: 'taskCard' },
  [SUBTASK_CARD_TYPE]: { name: '子任务', icon: SUBTASK_CARD_ICON, component: 'subtaskCard' },
  [IMAGE_TEXT_CARD_TYPE]: { name: '图文卡', icon: IMAGE_TEXT_CARD_ICON, component: 'imageTextCard' },
  [EXERCISE_CARD_TYPE]: { name: '习题卡', icon: EXERCISE_CARD_ICON, component: 'exerciseCard' },
  [SOURCE_CARD_TYPE]: { name: '资源卡', icon: SOURCE_CARD_ICON, component: 'sourceCard' },
  [SUBMIT_CARD_TYPE]: { name: '提交卡', icon: SUBMIT_CARD_ICON, component: 'submitCard' },
  [INTERACT_CARD_TYPE]: { name: '互动工具卡', icon: INTERACT_CARD_ICON, component: 'interactCard' },
  [GAUGE_CARD_TYPE]: { name: '量表', icon: GAUGE_CARD_ICON, component: 'gaugeCard' }
}
