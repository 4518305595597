import cardMap from './cardmap'

/**
 * 学程树转换平级结构
 * 量表转换成卡片并计算位置
 * 计算卡片序号
 * @param courseData
 * @returns {{parent: {}, component, data: any, name, sort: string, type: number}[]}
 */
export function flatCourseTree(courseData = {}, isVip) {
  console.log(courseData, 'courseData');
  // 先把学程卡放进去
  const courseCard = {
    name: cardMap[0].name,
    component: cardMap[0].component,
    data: { ...JSON.parse(JSON.stringify(courseData)), title: courseData.title || '暂无标题' },
    type: 0,
    id: courseData.id,
    sort: '0',
    parent: {}
  }
  const list = [courseCard]
  /**
   * arr 要处理的数组
   * allList 返回处理后的数据（为了和老数据进行拼接）
   * parentIndex 当前数组的父级索引
   * parentStructure 当前数组的父级结构
   */
  const pushItems = (arr, allList, parentIndex, parentStructure = []) => {
    arr.forEach((e, index) => {
      // 表格卡类型值 已废弃 处理一下
      e.itemType === 6 && (e.itemType = 4)
      // 结构拼接
      const sort = parentIndex ? `${parentIndex}.${index + 1}` : `${index + 1}`
      const structure = [
        ...parentStructure,
        {
          id: e.id,
          type: e.itemType,
          sort,
          title: e.title || cardMap[e.itemType].name
        }
      ]

      // 处理要push的item
      const item = {
        name: cardMap[e.itemType].name, // 卡片名称
        component: cardMap[e.itemType].component, // 卡片组件名
        data: { ...JSON.parse(JSON.stringify(e)), structure }, // 卡片数据
        type: e.itemType, // 卡片类型
        sort, // 卡片序号,
        parent: {},
        id: e.id
      }
      allList.push(item)

      // 将内部子结构递归
      e.children && e.children.length && pushItems(e.children, allList, item.sort, structure)

      // 最后判断添加量表
      if (e.gaugeItem) {
        // 量表的data是个字符串，需要特殊处理一下
        const gaugeStructure = [...structure, { id: null, type: 10, title: '量表' }]
        const gaugeItem = {
          name: cardMap[10].name,
          component: cardMap[10].component,
          data: JSON.stringify({
            ...JSON.parse(e.gaugeItem),
            structure: gaugeStructure,
            allStuNum: e.allStuNum,
            gaugeEvaluatedStuNum: e.gaugeEvaluatedStuNum
          }),
          type: 10,
          sort: `${cardMap[e.itemType].name} - ${item.sort} ${e.title || cardMap[e.itemType].name}`,
          parent: e, // 结构卡的量表父级为该结构卡或者该提交卡
          id: item.sort + '_' + e.id
        }
        allList.push(gaugeItem)
      }
    })
  }
  // 递归把内容的卡片放进去
  courseData.children && courseData.children.length && pushItems(courseData.children, list)

  // 最后把学程量表放进去
  if (courseData.gauge) {
    // 量表的 data 是个字符串，需要特殊处理一下
    const gaugeStructure = [
      {
        id: courseData.id,
        type: 0,
        title: courseData.title || '学程'
      },
      {
        id: null,
        type: 10,
        name: '量表'
      }
    ]
    const gaugeItem = {
      name: cardMap[10].name,
      component: cardMap[10].component,
      data: JSON.stringify({
        ...JSON.parse(courseData.gauge),
        structure: gaugeStructure,
        allStuNum: courseData.allStuNum,
        gaugeEvaluatedStuNum: courseData.gaugeEvaluatedStuNum
      }),
      type: 10,
      sort: `学程 - ${courseData.title}`,
      parent: courseData, // 学程量表父级为该学程
      id: 0 + '_' + courseData.id
    }
    list.push(gaugeItem)
  }
  let sliceList = list.slice(0, parseInt(list.length / 2))
  sliceList.push({ permissionFlag: true, component: 'tipCard', data: { ...JSON.parse(JSON.stringify(courseData)) } })

  if (isVip) {
    return sliceList
  } else {
    const bool1 = courseData.hasVipRole && courseData.isVipResource // 是vip角色并且是vip资源
    const bool2 = !courseData.isVipResource // 非vip资源
    const bool3 = courseData.accountType !== 2 // 非个人角色
    if (bool1 || bool2 || bool3) {
      return list
    } else {
      return sliceList
    }
  }
}

/**
 * 格式化选择题选项
 * A、=>''
 * A.=>''
 * A．=>''
 * A。=>''
 * (A) =>''
 * （A）=>''
 * @param html
 * @returns {*}
 */
export function formatChoiceOption(html) {
  // return (
  //   html &&
  //   html
  //     .replace(/[\n\r]/g, '')
  //     .replace(/&nbsp;/g, '')
  //     .replace(/([A-Z][\、|\．|.|\。])|[\(|\（][A-Z][\)|\）]/, '')
  // )
  const newHtml = html &&
    html
      .replace(/[\n\r]/g, '')
      .replace(/&nbsp;/g, '')
  const regExp = /([A-Z][\\、|\\．|.|\\。])|[\\(|\\（][A-Z][\\)|\\）]/
  //去掉所有的标签
  // const matchData1 = newHtml.(re)
  const text = filterStr(newHtml)
  const startData = text ? text.substring(0, 2) : ''
  const searchStart = startData.search(regExp)
  // console.log(searchStart)
  if (newHtml && searchStart > -1) { //确认开头存在
    const suitIndex = newHtml.search(regExp)
    const startHtml = newHtml.substring(0, suitIndex)
    const lastHtml = newHtml.substring(suitIndex + 2)

    return startHtml + lastHtml
  } else {
    return newHtml
  }
}

/**
 * 通过文件后缀名获取文件类型
 *fileName string
 */
export function getFileType(fileName) {
  let suffix = ''
  try {
    const fileArr = fileName.split('.')
    suffix = fileArr[fileArr.length - 1]
  } catch (err) {
    suffix = ''
  }
  return suffix
}

/**
 * 转化文件大小
 */
export function formatBytes(size, digit = 2) {
  if (size || size === 0) {
    if (size === 0) return '0 B'
    if (size < 0) return '未知'
    const ratio = 1024,
      unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(size) / Math.log(ratio))
    return parseFloat((size / Math.pow(ratio, f)).toFixed(digit)) + ' ' + unit[f]
  } else {
    return '大小转换失败'
  }
}

// 获取域名标识
// 当前host
const curHost = window.location.host
const envList = ['dev-edu', 'test', 'pre']
export const getHostNameKey = () => {
  let nameKey = ''
  envList.forEach(val => {
    if (curHost.includes(val)) {
      nameKey = val
    }
  })
  return nameKey
}

/**
 * 过滤资源类型
 */
// 所有的资源类型 (公共资源库、学程资源卡属性更多)
const allResourceType = [
  { value: 1, label: '教案' },
  { value: 2, label: '课件' },
  { value: 3, label: '习题' },
  { value: 4, label: '素材' },
  { value: 5, label: '资源包' },
  { value: 6, label: '微课' },
  { value: 7, label: '试卷' },
  { value: 9, label: '学程(电子版)' },
  { value: 11, label: '纪录片' },
  { value: 12, label: '优质资源' },
  { value: 8, label: '其他' }
]
export function getFileName(fileType) {
  const findItem = allResourceType.find(item => {
    return item.value === fileType
  })
  return findItem && findItem.label ? findItem.label : '暂无'
}

/**
 * 根据mime类型获取对应图片名称
 */
export const getImageByMimeType = (mimetype) => {
  const imgMap = {
    EXCEL: ['xls', 'xlsx'],
    DOC: ['doc', 'docx'],
    PDF: ['pdf'],
    PIC: ['png', 'jpg', 'jpeg', 'gif', 'webp'],
    PPT: ['ppt', 'pptx'],
    VOICE: ['mp3', 'mpeg-4', 'wav'],
    VIDEO: ['mp4', 'vs4', 'VS4']
  }
  const result = Object.keys(imgMap).find(key => {
    return imgMap[key].includes(mimetype.toLocaleLowerCase())
  })
  return result || 'OTHER'
}

/**
 * 数字索引转字母 1 -> A   26 -> Z
 * @param num
 * @returns {string}
 */
export function num2Letter(num) {
  const n = parseInt(num)
  if (!isNaN(n) && n > 0 && n < 27) {
    return String.fromCharCode(64 + n)
  }
}

/**
 * 处理标题，去掉标签 和空格符
 *
 */
export function filterStr(str) {
  const re = /<[^>]+>/g
  let newStr = str.replace(re, '')
  newStr = newStr.replace(/&nbsp;/gi, '')
  return newStr
}

export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}

/**
 * 特定元素相对于选区居中定位
 * @param target 表示悬浮定位元素
 * @param paragraph 表示选区所在目标元素，可以缺省
 */
export const showSelectionPopover = (target, paragraph) => {
  // 获得选区
  const selection = document.getSelection()
  const selectContent = selection && selection.toString()
  const selectContentTrim = selectContent && selectContent.trim()

  if (!selectContentTrim || !target) return

  // 如果有超出范围的内容
  // if (paragraph && paragraph.textContent.indexOf(selectContentTrim) === -1) {
  //   return console.warn('超出合法范围的选区')
  // }

  const range = selection.getRangeAt(0)

  // 重新修改选区，不包括前后的空格
  if (selectContent !== selectContentTrim) {
    const arrStartSpace = selectContent.match(/^\s+/g)
    const arrEndSpace = selectContent.match(/\s+$/g)
    if (arrStartSpace) {
      range.setStart(range.startContainer, range.startOffset + arrStartSpace[0].length)
    }
    if (arrEndSpace) {
      range.setEnd(range.endContainer, range.endOffset - arrEndSpace[0].length)
    }
  }

  const boundRange = range.getClientRects()[0] || range.getBoundingClientRect()

  // 定位处理
  // ps: 这里只处理窗体滚动的定位
  //     内部容器的滚动定位在这里修改处理

  target.style.display = 'block'
  target.style.top = boundRange.top - target.clientHeight + window.pageYOffset - 5 + 'px'
  target.style.left = boundRange.left + boundRange.width / 2 - target.clientWidth / 2 + 'px'
}

/**
 * 隐藏悬浮元素
 * @param target 表示悬浮定位元素
 */
export const hideSelectionPopover = target => {
  if (target) {
    target.style.display = 'none'
  }
}

/**
 * 获取选区在元素内的起止索引值，以及选区内容
 * @param selector
 * @returns {{[p: string]: unknown, startIndex: number, endIndex: number, selectContent: string}}
 */
export function getContentAndIndex(selector = '.content') {
  const selection = document.getSelection()
  const range = selection && selection.getRangeAt(0)
  let startNode = range && range.startContainer
  let startOffset = range && range.startOffset

  // 当前选区所在的元素
  let container = selection.anchorNode.parentElement.closest(selector)
  if (!container) {
    return console.error('不支持的选区')
  }
  if (!container) {
    // 这个多半是双击框选
    container = selection.anchorNode.parentElement.querySelector(selector)
    if (!container && selection.anchorNode.matches && selection.anchorNode.matches(selector)) {
      container = selection.anchorNode
    }
    // 需要改变位置计算的起点
    if (container) {
      startNode = container.firstChild
      // 可能是空节点
      if (!startNode.textContent) {
        startNode = startNode.nextSibling
      }
      startOffset = 0
    }
  }

  // 起始位置的计算
  let startIndex = 0
  const loopIndex = function (dom) {
    const nodes = [...dom.childNodes]
    nodes.some(function (node, index) {
      let text = node.textContent
      if (index === 0) {
        text = text.trimStart()
      }
      if (index === nodes.length - 1) {
        text = text.trimEnd()
      }
      if (!text) {
        return
      }
      // 节点匹配了
      // 不再遍历
      if (node === startNode) {
        startIndex += startOffset
        return true
      }

      if (startNode.parentNode === node) {
        loopIndex(node)
        return true
      }

      startIndex += node.textContent.length
    })
  }

  // container是内容的容器元素
  loopIndex(container)

  const selectContent = selection && selection.toString().trim()
  // 结束索引
  const endIndex = startIndex + selectContent.length

  return {
    startIndex,
    endIndex,
    selectContent
  }
}

export const showImagePopover = (target, paragraph) => {
  if (!target) return
  const boundRange = paragraph.getClientRects()[0] || paragraph.getBoundingClientRect()

  target.style.display = 'block'
  target.style.top = boundRange.top - target.clientHeight + window.pageYOffset - 1 + 'px'
  target.style.left = boundRange.left + boundRange.width / 2 - target.clientWidth / 2 + 'px'
}

/**
 * 隐藏悬浮元素
 * @param target 表示悬浮定位元素
 */
export const hideImagePopover = target => {
  if (target) {
    target.style.display = 'none'
  }
}

/**
 * 是不是url
 * @param str
 * @returns {boolean}
 */
export const isUrl = (str) => {
  const reg =
    '^((https|http|ftp|rtsp|mms)?://)' +
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
    '(([0-9]{1,3}.){3}[0-9]{1,3}' + // IP形式的URL- 199.194.52.184
    '|' + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
    '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' + // 二级域名
    '[a-z]{2,6})' + // first level domain- .com or .museum
    '(:[0-9]{1,4})?' + // 端口- :80
    '((/?)|' + // 如果没有文件名，则不需要斜杠
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$"
  return new RegExp(reg).test(str) ? true : false
}
/**
 * 是不是图片url或者base64
 * @param str
 * @returns {boolean}
 */
export const isImageUrlOrBase64 = str => {
  const urlReg = /^https?:\/\/(.+\/)+.+(\.(gif|png|jpg|jpeg|webp|bmp|tif))$/i
  const base64Reg = /data:image\/.*;base64,/
  return urlReg.test(str) || base64Reg.test(str)
}

/**
 * 是不是svg链接图片或者base64
 * @param str
 * @returns {boolean}
 */
export const isSvgUrlOrBase64 = str => {
  const svgUrlReg = /^https?:\/\/(.+\/)+.+(\.(svg))$/i
  return svgUrlReg.test(str) || (str && str.startsWith('data:image/svg+xml;base64,'))
}

/* 获取学程卡片序列号 */
export const getSerialNumber = (data) => {
  const structure = data.structure || []
  return structure.map(e => e.sort).join('.')
}

export const utils = {
  isLeaf(child) {
    return Array.isArray(child) && child.length
  },
  isEmpty(val) {
    // null or undefined
    if (val == null) return true

    if (typeof val === 'boolean') return false

    if (typeof val === 'number') return !val

    if (val instanceof Error) return val.message === ''

    switch (Object.prototype.toString.call(val)) {
      // String or Array
      case '[object String]':
      case '[object Array]':
        return !val.length

      // Map or Set or File
      case '[object File]':
      case '[object Map]':
      case '[object Set]': {
        return !val.size
      }
      // Plain Object
      case '[object Object]': {
        return !Object.keys(val).length
      }
    }

    return false
  },
  isString(obj) {
    return Object.prototype.toString.call(obj) === '[object String]'
  },
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  },
  isEqual(value1, value2) {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return this.arrayEquals(value1, value2)
    }
    return this.looseEqual(value1, value2)
  },
  arrayEquals(arrayA = [], arrayB = []) {
    if (arrayA.length !== arrayB.length) {
      return false
    }
    for (let i = 0; i < arrayA.length; i++) {
      if (!this.looseEqual(arrayA[i], arrayB[i])) {
        return false
      }
    }
    return true
  },
  looseEqual(a, b) {
    const isObjectA = this.isObject(a)
    const isObjectB = this.isObject(b)
    if (isObjectA && isObjectB) {
      return JSON.stringify(a) === JSON.stringify(b)
    } else if (!isObjectA && !isObjectB) {
      return String(a) === String(b)
    } else {
      return false
    }
  },
  // 处理时间
  formartDate(date) {
    const time = new Date(date) // 目标时间
    const nowTime = new Date() // 当前时间
    const year = time.getFullYear() // 目标年
    const nowYear = nowTime.getFullYear() // 当前年
    const month = time.getMonth() + 1 // 目标月
    const day = time.getDate() // 目标日
    const hours = time.getHours() // 目标时
    const min = time.getMinutes() // 目标分
    const nowDay = nowTime.getDate() //当前日
    const diff = +nowTime - +time
    const bool1 = diff < 1000 * 60 // 小于一分钟
    const bool2 = !bool1 && diff < 1000 * 60 * 60 // 小于一小时
    const bool3 = !bool2 && nowDay === day // 当天
    const bool4 = !bool3 && (nowDay - day === 1) // 昨天
    const bool5 = !bool4 && year === nowYear // 今年
    const nowHours = this.dealDataSuit(hours)
    const nowMin = this.dealDataSuit(min)
    const newDay = this.dealDataSuit(day)
    let res = ''
    if (bool1) {
      res = '刚刚'
    } else if (bool2) {
      res = `${Math.ceil(diff / 1000 / 60)}分钟前`
    } else if (bool3) {
      res = `今天${nowHours}:${nowMin}`
    } else if (bool4) {
      res = `昨天${nowHours}:${nowMin}`
    } else if (bool5) {
      res = `${month}月${newDay}日 ${nowHours}:${nowMin}`
    } else {
      res = `${year}年${month}月${newDay}日 ${nowHours}:${nowMin}`
    }
    return res
  },
  //处理数字成两位
  dealDataSuit(value) {
    return value < 10 ? '0' + value : value
  },
  // 判断对象有且仅有这些属性
  hasSomeProperties(obj, params) {
    if (Object.prototype.toString.call(obj) !== '[object Object]') return false
    if (!Array.isArray(params) || !params.length) return false
    if (Object.keys(obj).length !== params.length) return false
    return params.every(item => Object.prototype.hasOwnProperty.call(obj, item))
  },
  // 正则匹配字符串内图片
  getImageList(str) {
    const srcList = []
    const imgReg = /<img.*?(?:>|\/>)/gi, srcReg = /src=[\\'\\"]?([^\\'\\"]*)[\\'\\"]?/i
    const imgList = str.match(imgReg) || []
    for (let i = 0; i < imgList.length; i++) {
      const src = imgList[i].match(srcReg)
      if (src && src[1] && this.isImageUrlOrBase64(src[1])) {
        srcList.push(src[1])
      }
    }
    return srcList
  },
  /**
   * 是不是图片url或者base64
   * @param str
   * @returns {boolean}
   */
  isImageUrlOrBase64(str) {
    const urlReg = /^https?:\/\/(.+\/)+.+(\.(gif|png|jpg|jpeg|webp|bmp|tif))$/i
    const base64Reg = /data:image\/.*;base64,/
    return urlReg.test(str) || base64Reg.test(str)
  }
}
