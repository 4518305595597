import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { encode } from "js-base64";
export default {
  name: "previewIframe",
  props: {
    path: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      showPath: "",
      preview: "https://filepreview.zhixuehuijiao.cn/onlinePreview?url=",
      iframeLoading: true
    };
  },
  watch: {
    path: {
      handler: function handler(nval) {
        var _this = this;
        if (nval) {
          var code = encodeURIComponent(encode(nval.indexOf('//') === 0 ? 'https:' + nval : nval));
          this.showPath = this.preview + code;
          this.$nextTick(function () {
            return _this.load();
          });
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.resize();
  },
  beforeDestroy: function beforeDestroy() {
    var _this2 = this;
    this.showPath = "";
    window.removeEventListener("resize", function () {
      return _this2.iframeInit();
    }, false);
  },
  methods: {
    // 显示等待框
    show: function show() {
      this.iframeLoading = true;
    },
    // 隐藏等待狂
    hide: function hide() {
      this.iframeLoading = false;
    },
    // 加载浏览器窗口变化自适应
    resize: function resize() {
      var _this3 = this;
      window.addEventListener("resize", function () {
        return _this3.iframeInit();
      }, false);
    },
    // 加载组件
    load: function load() {
      var _this4 = this;
      this.show();
      var time = 5;
      var timeFunc = setInterval(function () {
        time--;
        if (time === 0) {
          _this4.hide();
          clearInterval(timeFunc);
        }
      }, 1000);
      //处理切换iframe地址 导致router 需要多次才能跳转页面的问题
      this.$refs.iframe.contentWindow.location.replace(this.showPath);
      this.iframeInit();
    },
    // iframe窗口初始化
    iframeInit: function iframeInit() {
      var _this5 = this;
      this.$nextTick(function () {
        var iframe = _this5.$refs.iframe;
        if (iframe) {
          if (iframe.attachEvent) {
            iframe.attachEvent("onload", function () {
              _this5.hide();
            });
          } else {
            iframe.onload = function () {
              _this5.hide();
            };
          }
        }
      });
    }
  }
};