import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "ks-player-box",
  class: "ks-wrapper"
};
var _hoisted_2 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("video", {
    id: "ks-video-".concat($props.videoPlayerId),
    width: "800",
    height: "600"
  }, "您的浏览器不支持 video 标签。", 8, _hoisted_2)]);
}