export const getEnvName = () => {
  return (
    ['dev-edu', 'test', 'pre'].find(v => {
      return location.host.includes(v);
    }) || ''
  );
};

export const getHostName = () => {
  let target = '';
  if (process.env.NODE_ENV === 'production') {
    const hosts = window.location.host.split('.').reverse();
    target = hosts.length > 1 ? `${hosts[1]}.${hosts[0]}` : 'huixuecloud.cn';
  }
  return target;
};

const userAgent = window?.navigator?.userAgent
const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1
export const isTeacherPad = isAndroid && userAgent.indexOf('zxhjTeacherApk') > -1 // 是不是教师端平板端
export const isStudentPad = isAndroid && userAgent.indexOf('zxhjStudentApk') > -1 // 是不是学生端平板端
export const isPad = isAndroid && userAgent.indexOf('zxhj') > -1 // 是不是课堂互动平板端
