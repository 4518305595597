import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/dialog-close.svg';
var _hoisted_1 = {
  key: 0,
  class: "resource-preview-dialog"
};
var _hoisted_2 = {
  class: "head"
};
var _hoisted_3 = {
  class: "resource-info"
};
var _hoisted_4 = {
  key: 0,
  class: "resource-name"
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  key: 2
};
var _hoisted_7 = {
  key: 0,
  class: "resource-preview"
};
import { reactive, onMounted } from "vue";
/* 组件 */
import kanshanVideo from "./kanshanVideo";
import preview from "./preview";
/* api */
import { getResourceInfo } from "@/api/resources";
/* 变量 */

export default {
  __name: 'index',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceData: {
      type: Object,
      default: function _default() {}
    },
    width: {
      type: String,
      default: '80vw'
    }
  },
  emits: ["update:visible"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    /* 工具 */
    var allResourceType = [{
      value: 'lesson_plan',
      label: "教案"
    }, {
      value: 'courseware',
      label: "课件"
    }, {
      value: 'material_exercise',
      label: "习题"
    }, {
      value: 'resource_pkg',
      label: "资源包"
    }, {
      value: 'micro_class',
      label: "微课"
    }, {
      value: 'expand_resource',
      label: "拓展资源"
    }, {
      value: 'material_other',
      label: "其他"
    }, {
      value: 4,
      label: "素材"
    }, {
      value: 7,
      label: "试卷"
    }, {
      value: 9,
      label: "学程(电子版)"
    }, {
      value: 11,
      label: "纪录片"
    }, {
      value: 12,
      label: "优质资源"
    }];
    var emit = __emit;
    var state = reactive({
      sourceData: {},
      isLoad: false
    });
    /* 方法 */
    onMounted(function () {
      if (!__props.sourceData.path && !__props.sourceData.url) {
        getResourceInfo(__props.sourceData.id).then(function (res) {
          if (res.data.code === 0) {
            state.sourceData = res.data.data;
            state.isLoad = true;
          }
        });
      } else {
        state.sourceData = __props.sourceData;
        state.isLoad = true;
      }
    });
    var getFileType = function getFileType(type) {
      var data = allResourceType.find(function (e) {
        return e.value === type;
      });
      return data === null || data === void 0 ? void 0 : data.label;
    };
    var hide = function hide() {
      emit("update:visible", false);
    };
    return function (_ctx, _cache) {
      var _component_t_dialog = _resolveComponent("t-dialog");
      return __props.visible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_t_dialog, {
        visible: __props.visible,
        footer: false,
        width: __props.width,
        closeBtn: false,
        placement: "center",
        onClose: hide
      }, {
        header: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
            class: "close-btn",
            src: _imports_0,
            alt: "",
            onClick: hide
          }), _createElementVNode("div", _hoisted_3, [state.sourceData.name ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(state.sourceData.name), 1)) : _createCommentVNode("", true), !isNaN(state.sourceData.isOriginal) ? (_openBlock(), _createElementBlock("span", _hoisted_5, "原创声明：" + _toDisplayString(state.sourceData.isOriginal === 1 ? '是' : '否'), 1)) : _createCommentVNode("", true), state.sourceData.typeDictItemCode ? (_openBlock(), _createElementBlock("span", _hoisted_6, "资源类型：" + _toDisplayString(getFileType(state.sourceData.typeDictItemCode)), 1)) : _createCommentVNode("", true)])])];
        }),
        default: _withCtx(function () {
          return [state.isLoad ? (_openBlock(), _createElementBlock("div", _hoisted_7, [state.sourceData && state.sourceData.sourceType === 2 && state.sourceData.formatType.toUpperCase() === 'VS4' ? (_openBlock(), _createBlock(_unref(kanshanVideo), {
            key: 0,
            "video-player-id": state.sourceData.path,
            "video-url": state.sourceData.path,
            "video-poster": state.sourceData.coverPath,
            "ks-resource-id": state.sourceData.sourceId,
            "sub-title": state.sourceData.subtitle
          }, null, 8, ["video-player-id", "video-url", "video-poster", "ks-resource-id", "sub-title"])) : (_openBlock(), _createBlock(_unref(preview), {
            key: 1,
            path: state.sourceData.url
          }, null, 8, ["path"]))])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["visible", "width"])])) : _createCommentVNode("", true);
    };
  }
};