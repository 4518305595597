import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var sleep = function sleep(now, s) {
  while (Date.now - now <= s);
};
import asyncLoadScript from './asyncLoadScript';
import { getHostNameKey } from '@/views/sourcePreview/courseDetail/const/utils';
var videoJS = '/cdn/video-20240205.js';
var statisticsJS = 'https://track.viewshare.cn/271edu.min.js';
export default {
  name: 'kanshanVideo',
  props: {
    videoPlayerId: {
      type: String,
      default: '',
      require: true
    },
    videoUrl: {
      type: String,
      default: '',
      require: true
    },
    videoPoster: {
      type: String,
      default: '',
      require: true
    },
    ksResourceId: {
      type: [String, Number],
      default: '',
      require: true
    },
    subTitle: {
      type: String,
      default: '',
      require: true
    }
  },
  data: function data() {
    return {
      videoPlayer: null,
      lastPlayerId: null
    };
  },
  watch: {
    videoUrl: function videoUrl(nval) {
      nval && this.reloadVideo();
    }
  },
  created: function created() {
    var _this = this;
    var loadVideoJS = asyncLoadScript(videoJS, window.Module);
    var loadStatisticsJS = asyncLoadScript(statisticsJS, window.setVideoStatistics);
    Promise.all([loadVideoJS, loadStatisticsJS]).then(function (src) {
      return _this.initVideo();
    }).catch(function (err) {
      return _this.$message.error('视频播放器初始化失败');
    });
  },
  beforeDestroy: function beforeDestroy() {
    this.videoPlayer.stop();
  },
  destroyed: function destroyed() {
    this.videoPlayer.stop();
  },
  methods: {
    initVideo: function initVideo() {
      try {
        this.lastPlayerId = "ks-video-".concat(this.videoPlayerId);
        var captions = (this.subTitle ? JSON.parse(this.subTitle) : {}) || this.subTitle;
        var originKey = getHostNameKey();
        for (var val in captions) {
          captions[val] = '//kanshan-resource.zhixuehuijiao.cn/' + captions[val] + '?origin=' + originKey + '-teach';
        }
        console.log('Module111', window.Module);

        // eslint-disable-next-line no-undef
        this.videoPlayer = Module.createViewBoxPlayer({
          t: '',
          // 此参数暂不可用，非必传
          wrap: document.getElementById("ks-video-".concat(this.videoPlayerId)),
          // 实例化播放器所对应标签
          videouri: "/".concat(this.videoUrl, "?origin=").concat(originKey, "-teach"),
          // 视频地址，无需添加前缀
          videoposter: "/".concat(this.videoPoster),
          // 视频封面图，无需添加前缀
          subtitle: captions,
          // 字幕文件地址
          autoplay: true // 是否自动播放
        });
        this.videoPlayer.play(); // 播放器初始化方法，需在播放前调用
        // eslint-disable-next-line no-undef
        VideoStatistics && new VideoStatistics({
          //看山统计
          boxId: '#ks-player-box',
          // 放video元素的父元素的id，注意前面的#需要加上（String类型，必填）
          resourceId: this.ksResourceId // 看山平台的资源id（改字符串类型了，必填）
        });
      } catch (err) {
        this.$message.error(err.message || '资源播放失败，请重试');
      }
    },
    reloadVideo: function reloadVideo() {
      var video = document.getElementById(this.lastPlayerId);
      var now = Date.now();
      if (video) {
        this.videoPlayer.stop();
        document.getElementById(this.lastPlayerId).remove();
        sleep(now, 1000);
      }
      var videoHtml = '<video id="ks-video-' + this.videoPlayerId + '" width="800" height="600" controls="controls">您的浏览器不支持 video 标签。</video>';
      var videoHtmlInit = document.getElementById('ks-player-box');
      videoHtmlInit.innerHTML = videoHtml + videoHtmlInit.innerHTML;
      this.initVideo();
    }
  }
};