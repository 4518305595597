// 普通编辑
export const NORMAL_EDIT = 'normalEdit'
// 发起者协作者 协作编辑
export const GROUP_EDIT = 'groupEdit'
// 参与者 协作编辑只读
export const GROUP_EDIT_READONLY = 'groupEditReadonly'

/****************************卡片类型值定义***************************/
// 学程标题卡类型值
export const COURSE_CARD_TYPE = 0
// 任务群结构卡类型值
export const TASK_GROUP_CARD_TYPE = 1
// 任务结构卡类型值
export const TASK_CARD_TYPE = 2
// 子任务结构卡类型值
export const SUBTASK_CARD_TYPE = 3
// 图文卡类型值
export const IMAGE_TEXT_CARD_TYPE = 4
// 习题卡类型值
export const EXERCISE_CARD_TYPE = 5
// 表格卡类型值 已废弃
export const TABLE_CARD_TYPE = 6
// 资源卡类型值
export const SOURCE_CARD_TYPE = 7
// 提交卡类型值
export const SUBMIT_CARD_TYPE = 8
// 互动工具卡类型值
export const INTERACT_CARD_TYPE = 9
// 量表卡类型值
export const GAUGE_CARD_TYPE = 10

/****************************卡片用途类型值定义***************************/
export const CARD_USE_TYPE = {
  NORMAL_EDIT: 'normal-edit', // 普通编辑
  GROUP_EDIT: 'group-edit', // 发起者、协作者协作编辑
  GROUP_EDIT_READONLY: 'group-edit-readonly', // 参与者协作编辑、教研结束后不可编辑
  EDIT_PREVIEW: 'edit-preview', // 编辑预览
  COURSE_PREVIEW: 'course-preview', // 学程预览
  COURSE_DEMONSTRATION: 'course-demonstration' // 学程演示
}

/****************************卡片图标值定义***************************/
// 学程标题卡图标
export const COURSE_CARD_ICON = 'icon-course-card'
// 任务群卡图标
export const TASK_GROUP_CARD_ICON = 'icon-task-group-card'
// 任务卡图标
export const TASK_CARD_ICON = 'icon-task-card'
// 子任务卡图标
export const SUBTASK_CARD_ICON = 'icon-subtask-card'
// 图文卡图标
export const IMAGE_TEXT_CARD_ICON = 'icon-image-text-card'
// 习题卡图标
export const EXERCISE_CARD_ICON = 'icon-exercise-card'
// 资源卡图标
export const SOURCE_CARD_ICON = 'icon-source-card'
// 提交卡图标
export const SUBMIT_CARD_ICON = 'icon-submit-card'
// 互动工具卡图标
export const INTERACT_CARD_ICON = 'icon-interact-card'
// 量表卡图标
export const GAUGE_CARD_ICON = 'icon-gauge-card'
