/**
 *  load-script-promise
 * @param src
 * @param existVariable
 * @returns {Promise<unknown>}
 */
const asyncLoadScript = (src, existVariable) => {
  return new Promise((resolve, reject) => {
    loadScript(src, existVariable, (err) => {
      if (err) {
        reject(err)
      } else {
        resolve(src)
      }
    })
  })
}

function loadScript(src, existVariable, callback) {
  const existingScript = document.getElementById(src)
  const cb = callback || function() {}
  if (!existingScript) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.id = src
    document.body.appendChild(script)
    const onEnd = 'onload' in script ? stdOnEnd : ieOnEnd
    onEnd(script)
  }
  if (existingScript && cb) {
    if (existVariable) {
      cb(null, existingScript)
    }
  }
  function stdOnEnd(script) {
    script.onload = function() {
      this.onerror = this.onload = null
      cb(null, script)
    }
    script.onerror = function() {
      this.onerror = this.onload = null
      cb(new Error('Failed to load ' + src), script)
    }
  }

  function ieOnEnd(script) {
    script.onreadystatechange = function() {
      if (this.readyState !== 'complete' && this.readyState !== 'loaded') return
      this.onreadystatechange = null
      // 无法捕获IE8中的加载错误
      cb(null, script)
    }
  }
}

export default asyncLoadScript
